/* eslint no-console: 0 */
// Run this example by adding <%= javascript_include_tag 'hello_vue' %> (and
// <%= stylesheet_link_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue/dist/vue.esm'
import '../css/application.css'

function lazyLoadAssets() {
  const targets = document.querySelectorAll('.lazy')

  targets.forEach((target) => {
    const src = target.getAttribute('data-src')

    if (src) {
      target.setAttribute('src', src)
    }
  })
}

function lazyLoadGoogleFonts() {
  const link = document.createElement('link')

  link.href =
    'https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700|Poppins:400,700&display=swap&subset=japanese'
  link.rel = 'stylesheet'
  link.media = 'all'

  document.head.appendChild(link)
}

function lazyLoadTwitterWidgets() {
  const script = document.createElement('script')

  script.charset = 'utf-8'
  script.src = 'https://platform.twitter.com/widgets.js'

  document.head.appendChild(script)
}

new Vue({
  el: '.js-app',
  data() {
    return {
      isOpen: false,
      login: true,
      termsAgreement: false
    }
  },
  mounted() {
    lazyLoadAssets()
    lazyLoadGoogleFonts()
    lazyLoadTwitterWidgets()
  }
})

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_include_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>

// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
